import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Link } from 'gatsby';
import Img from 'gatsby-image';

export default function Projects() {
  const data = useStaticQuery(
    graphql`
      {
        allMarkdownRemark(
          filter: {
            fields: { collection: { eq: "projects" } }
          }
          sort: { order: DESC, fields: frontmatter___date }
        ) {
          edges {
            node {
              frontmatter {
                type
                title
                description
                date(formatString: "MMM D, YYYY")
                cover {
                  childImageSharp {
                    fluid(maxWidth: 512) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
              fields {
                slug
              }
            }
          }
        }
      }
    `,
  );

  return (
    <React.Fragment>
      <div className="relative bg-gray-50 pb-20 px-4 sm:px-6 lg:pb-28 lg:px-8">
        <div className="absolute inset-0">
          <div className="bg-white h-1/3 sm:h-2/3"></div>
        </div>
        <div className="relative max-w-7xl mx-auto">
          <div className="text-center">
            <h2 className="text-3xl leading-9 tracking-tight font-extrabold text-gray-900 sm:text-4xl sm:leading-10">
              UX Research and PhD Research Projects
            </h2>
            <p className="mt-3 max-w-2xl mx-auto text-xl leading-7 text-gray-500 sm:mt-4">
              {/* Research projects and course projects in chronological order. */}
            </p>
          </div>
          <div className="mt-12 grid gap-5 max-w-lg mx-auto lg:grid-cols-3 lg:max-w-none">
            {data.allMarkdownRemark.edges.map((edge) => (
              <Project edge={edge} />
            ))}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export function Project({ edge }) {
  return (
    <React.Fragment>
      <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
        <div className="flex-shrink-0">
          {/* <img
            className="h-48 w-full object-cover"
            src="https://images.unsplash.com/photo-1492724441997-5dc865305da7?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1650&q=80"
            alt=""
          /> */}
          <Img
            fluid={
              edge.node.frontmatter.cover.childImageSharp
                .fluid
            }
            className="h-48 w-full object-cover"
          />
        </div>
        <div className="flex-1 bg-white p-6 flex flex-col justify-between">
          <div className="flex-1">
            <p className="text-sm leading-5 font-medium text-green-600">
              {/* <a href="/" className="hover:underline">
                {edge.node.frontmatter.type}
              </a> */}
              {edge.node.frontmatter.type}
            </p>
            <Link
              to={edge.node.fields.slug}
              className="block"
            >
              <h3 className="mt-2 text-xl leading-7 font-semibold text-gray-900">
                {edge.node.frontmatter.title}
              </h3>
              <p className="mt-3 text-base leading-6 text-gray-500">
                {edge.node.frontmatter.description}
              </p>
            </Link>
          </div>
          {/* <div className="mt-6 flex items-center">
            <div className="flex-shrink-0">
              <a href="/">
                <img
                  className="h-10 w-10 rounded-full"
                  src="https://images.unsplash.com/photo-1487412720507-e7ab37603c6f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                  alt=""
                />
              </a>
            </div>
            <div className="ml-3">
              <p className="text-sm leading-5 font-medium text-gray-900">
                <a href="/" className="hover:underline">
                  Daniela Metz
                </a>
              </p>
              <div className="flex text-sm leading-5 text-gray-500">
                <time dateTime="2020-03-16">
                  Mar 16, 2020
                </time>
                <span className="mx-1">&middot;</span>
                <span>6 min read</span>
              </div>
            </div>
          </div> */}
        </div>
      </div>{' '}
    </React.Fragment>
  );
}
