import React from 'react';
import Seo from 'components/Seo/Seo';
import Nav from 'components/Nav/Nav';
import Projects from 'components/Projects/Projects';
import Cta from 'components/Cta/Cta';
import Contact from 'components/Contact/Contact';
import Footer from 'components/Footer/Footer';

export default function ContactPage() {
  return (
    <React.Fragment>
      <Seo title="Home" />
      <Nav />
      <Cta />
      <Contact />
      <Footer />
    </React.Fragment>
  );
}
